<template>
  <div class="videoDirectorConfigEdit">
    <VideoDirectorConfiguration
      :installation-id="installationId"
      :lane-number="laneNumber"
    />
  </div>
</template>

<script>
export default {
  name: "VideoDirectorConfigEdit",
  components: {
    VideoDirectorConfiguration: () => import('@/components/Config/VideoDirector/VideoDirectorConfiguration.vue')
  },
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  metaInfo () {
    return {
      title: 'Video Director Config'
    }
  }
}
</script>
